<template>
	<loan-content-layout v-if="isInitialized">
		<v-row>
			<v-col class="d-flex flex-column pt-4" style="gap: 2px">
				<v-card elevation="0"
						class="loan-card px-6 pt-3 pb-6"
						style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
					<v-row>
						<v-col class="d-flex" :style="{ 'flex-direction': $vuetify.breakpoint.lgAndUp ? '' : 'column' }">
							<div class="d-flex flex-wrap" style="gap: 0 24px">
								<div class="d-flex" style="gap: 24px">
									<frp-range-date-field :label="$t('fields.plannedPaymentDate.label')"
														  type="string"
														  dense
														  v-model="filterPaymentDatesRange"
														  hide-details
														  :placeholder="$t('fields.plannedPaymentDate.placeholder')">
									</frp-range-date-field>

									<frp-autocomplete :label="$t('fields.quarter.label')"
													  v-if="isFullMode"
													  item-text="title"
													  item-value="id"
													  color="blue"
													  hide-details
													  multiple
													  v-model="internalFilterValues.quarterCodeIds"
													  :items="quarters"
													  :placeholder="$t('fields.quarter.placeholder')"
													  auto
													  style="max-width: 145px">
									</frp-autocomplete>

									<frp-autocomplete :label="$t('fields.paymentExpense.label')"
													  v-model="internalFilterValues.paymentExpenseIds"
													  multiple
													  :items="filteredPaymentExpenses"
													  item-text="name"
													  item-value="id"
													  color="blue"
													  :placeholder="$t('fields.paymentExpense.placeholder')"
													  hide-details>
									</frp-autocomplete>
								</div>

								<frp-radio-group class="pt-5 pb-0"
												 v-if="isFullMode"
												 color="blue"
												 no-wrap
												 :highlight-item-value="CashFlowPaymentType.CESSION"
												 :highlight-item-color="colors.warning.base"
												 v-model="internalFilterValues.paymentType"
												 :items="paymentTypes">
								</frp-radio-group>
								
								<frp-radio-group class="pt-5 pb-0"
												 v-if="!isFullMode && isLoanUserBorrower"
												 color="blue"
												 no-wrap
												 v-model="internalFilterValues.paymentType"
												 :items="borrowerRolePaymentTypes">
								</frp-radio-group>
							</div>

							<v-spacer></v-spacer>

							<div class="d-flex pt-5 ml-3">
								<frp-btn color="primary"
										 outlined
										 @click="resetFilter"
										 :disabled="isFilterEmpty && !isFilterChanged">
									{{ $t("buttons.clearAll") }}
								</frp-btn>
								<frp-btn elevation="0"
										 color="blue"
										 dark
										 :disabled="!isFilterChanged"
										 @click="applyFilter">
									{{ $t("buttons.accept") }}
								</frp-btn>
							</div>
						</v-col>
					</v-row>
				</v-card>

				<v-card elevation="0"
						class="loan-card px-6 py-6"
						style="border-top-left-radius: 0; border-top-right-radius: 0;">
					<v-row>
						<v-col>
							<v-form v-model="isNewItemFormValid">
								<v-data-table :headers="headers"
											  :loading-text="$t('tables.loading')"
											  :loading="isItemsLoading"
											  hide-default-footer
											  item-key="id"
											  :items="formattedItems"
											  :items-per-page="-1"
											  :mobile-breakpoint="mobileBreakpoint"
											  :options="options"
											  :item-class="() => 'text-body-2 cy-table-row'"
											  class="loan-table clickable-rows d-flex flex-column">
									<template #header>
										<tr class="table-header">
											<td colspan="100">
												<div class="d-flex justify-end">
													<frp-btn dense
															 v-if="can(Permissions.LOAN_CREATE)"
															 outlined
															 style="text-transform: none"
															 color="blue"
															 dark
															 @click="handleOpenNewItemDialog"
															 elevation="0">
														{{ $t("buttons.addManually") }}
													</frp-btn>
												</div>
											</td>
										</tr>
									</template>

									<template #item.numberPaymentOrder="{ item }">
										<span>{{ item.numberPaymentOrder || "--" }}</span>
									</template>

									<template #item.quarter="{ item }">
										<span>{{ quarters.find(x => x.id === item.quarterId)?.title || "--" }}</span>
									</template>

									<template #item.source="{ item }">
										<span>{{ allFinanceSources.find(x => x.id === item.financingSourceId)?.name || "--" }}</span>
									</template>

									<template #item.paymentDate="{ item }">
										<span>{{ formatDate(item.paymentDate, dateFormat) || "--" }}</span>
									</template>

									<template #item.amount="{ item }">
										<span>{{ formatCurrency(item.amount) || "--" }}</span>
									</template>

									<template #item.paymentExpense="{ item }">
										<span>{{ paymentExpenses.find(x => x.id === item.paymentExpenseId)?.name || "--" }}</span>
									</template>

									<template #item.isForced="{ item }">
										<span>{{ item.isForced ? $t("aliases.affirmation.yes") : "" }}</span>
									</template>

									<template #item.description="{ item }">
										<span>{{ item.description || "--" }}</span>
									</template>

									<template #item.action1="{ item }">
										<frp-btn without-padding
												 v-if="can(Permissions.LOAN_UPDATE)"
												 small
												 icon
												 color="blue"
												 dark
												 :disabled="item.isDeleted"
												 @click="handleOpenUpdateItemDialog(item)">
											<frp-icon src="ico_edit" :color="colors.primary.darken1"></frp-icon>
										</frp-btn>
									</template>

									<template #item.action2="{ item }">
										<frp-btn without-padding
												 v-if="can(Permissions.LOAN_DELETE)"
												 small
												 icon
												 color="blue"
												 dark
												 :loading="isItemDeleting"
												 :disabled="item.isDeleted"
												 @click="handleOpenDeleteItemDialog(item.id)">
											<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
										</frp-btn>
									</template>

									<template #foot>
										<tfoot class="text-subtitle-1 font-weight-medium grey--text text--darken-3">
										<tr>
											<template v-if="isMobileBreakpoint">
												<td class="d-flex justify-space-between mt-5">
													<span>{{ $t("content.total") }}</span>
													<span>{{ sum }}</span>
												</td>
											</template>

											<template v-else>
												<td :colspan="isFullMode ? 3 : 1"/>
												<td>
													<span>{{ $t("content.total") }}</span>
												</td>
												<td style="position: relative">
													<span>{{ sum }}</span>
												</td>
											</template>
										</tr>
										<tr v-if="isFullMode && reservedTotalAmount !== null">
											<template v-if="isMobileBreakpoint">
												<td class="d-flex justify-space-between mt-5">
													<span>{{ $t("content.reservedTotalAmount") }}</span>
													<span>{{ formatCurrency(reservedTotalAmount) }}</span>
												</td>
											</template>

											<template v-else>
												<td :colspan="isFullMode ? 3 : 1" />
												<td>
													<span>{{ $t("content.reservedTotalAmount") }}</span>
												</td>
												<td style="position: relative">
													<span>{{ formatCurrency(reservedTotalAmount) }}</span>
												</td>
											</template>
										</tr>
										</tfoot>
									</template>
								</v-data-table>
							</v-form>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>

		<frp-dialog v-model="isUpdateItemDialogOpened" :title="$t('dialogs.paymentEditing.title')" persistent>
			<template #content="{ onIntersect }">
				<v-form ref="updateItemForm" v-intersect="onIntersect" v-model="isUpdateItemFormValid">
					<frp-text-field v-model="editableItemNumberPaymentOrder"
									:label="$t('fields.numberPaymentOrder.label')"
									:placeholder="$t('fields.numberPaymentOrder.placeholder')">
					</frp-text-field>

					<frp-autocomplete :label="$t('fields.borrower.label')"
									  v-model="editableItemProjectId"
									  :rules="validation.projectId"
									  :items="borrowers"
									  :min-query-length="MIN_BORROWERS_QUERY_LENGTH"
									  item-text="name"
									  item-value="projectId"
									  color="blue"
									  required
									  :placeholder="$t('fields.borrower.placeholder')">
					</frp-autocomplete>

					<frp-autocomplete :label="$t('fields.paymentExpense.label')"
									  v-model="editableItemPaymentExpense"
									  :rules="validation.paymentExpenses"
									  :items="paymentExpenses"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.paymentExpense.label')">
					</frp-autocomplete>

					<frp-autocomplete :label="$t('fields.financeSource.label')"
									  v-model="editableItemSource"
									  :rules="validation.source"
									  :items="filteredFinanceSources"
									  :loading="isDictionariesLoading"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.financeSource.placeholder')">
					</frp-autocomplete>

					<frp-autocomplete :label="$t('fields.quarter.label')"
									  v-model="editableItemQuarter"
									  :rules="validation.quarter"
									  :items="dynamicQuarters"
									  :loading="isDictionariesLoading"
									  item-value="id"
									  item-text="title"
									  color="blue"
									  required
									  auto
									  :placeholder="$t('fields.quarter.label')">
					</frp-autocomplete>

					<frp-date-field :label="$t('fields.date.label')"
									v-model="editableItemPaymentDate"
									:rules="validation.paymentDate"
									required
									:placeholder="$t('fields.date.placeholder')">
					</frp-date-field>

					<frp-text-field :label="$t('fields.amount.labelRUB')"
									v-model.number="editableItemAmount"
									:rules="validation.amount"
									type="number"
									required
									:placeholder="$t('fields.amount.placeholder')">
					</frp-text-field>

					<template v-if="isActiveDateVisible">
						<frp-date-field :label="$t('fields.paymentActiveDate.label')"
										v-model="editableItemActiveDate"
										:rules="validation.activeDate"
										required
										:placeholder="$t('fields.paymentActiveDate.placeholder')">
						</frp-date-field>
					</template>

					<frp-checkbox v-model="editableItemIsForced"
								  :true-value="true"
								  :false-value="false"
								  class="mb-4 mt-0"
								  color="blue"
								  :text="$t('buttons.isForcedPayment')">
					</frp-checkbox>

					<v-row>
						<v-col>
							<frp-checkbox v-model="editableItemIsCession"
										  :true-value="true"
										  :false-value="false"
										  class="mb-4 mt-0"
										  color="blue"
										  :text="$t('buttons.isCessionPayment')">
							</frp-checkbox>
						</v-col>
						<v-col>
							<frp-checkbox v-model="editableItemIsReserve"
										  :true-value="true"
										  :false-value="false"
										  class="mb-4 mt-0"
										  color="blue"
										  :text="$t('buttons.isReservePayment')">
							</frp-checkbox>
						</v-col>
					</v-row>

					<frp-textarea :label="$t('fields.paymentDescription.label')"
								  autocomplete="off"
								  :placeholder="$t('fields.paymentDescription.placeholder')"
								  outlined
								  v-model="editableItemDescription">
					</frp-textarea>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 color="primary"
						 @click="handleCancelUpdateItem">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 color="blue"
						 dark
						 :loading="isFormSaving"
						 :disabled="isFormSaving || !isUpdateItemFormValid"
						 @click="handleUpdateItem">
					{{ $t("buttons.edit") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isDeleteItemDialogOpened" :title="$t('dialogs.deletePayment.title')">
			<template #content="{ onIntersect }">
				<span v-intersect="onIntersect" class="primary--text text-body-2">
					{{ $t("dialogs.deletePayment.warning") }}
				</span>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="handleCancelItemDeleting"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 @click="handleDeleteItem">
					{{ $t("buttons.remove") }}
				</frp-btn>
			</template>
		</frp-dialog>

		<frp-dialog v-model="isNewItemDialogOpened" :title="$t('dialogs.addPayment.title')" persistent>
			<template #content="{ onIntersect }">
				<v-form :ref="refs.form" v-intersect="onIntersect" v-model="formValid">
					<frp-text-field v-model="editableItemNumberPaymentOrder"
									:label="$t('fields.numberPaymentOrder.label')"
									:placeholder="$t('fields.numberPaymentOrder.placeholder')">
					</frp-text-field>
					
					<frp-autocomplete :label="$t('fields.borrower.label')"
									  v-model="editableItemProjectId"
									  :rules="validation.projectId"
									  :items="borrowers"
									  :min-query-length="MIN_BORROWERS_QUERY_LENGTH"
									  item-text="name"
									  item-value="projectId"
									  color="blue"
									  required
									  :placeholder="$t('fields.borrower.placeholder')">
					</frp-autocomplete>
					
					<frp-autocomplete :label="$t('fields.paymentExpense.label')"
									  v-model="editableItemPaymentExpense"
									  :rules="validation.paymentExpenses"
									  :items="paymentExpenses"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.paymentExpense.label')">
					</frp-autocomplete>
					
					<frp-autocomplete :label="$t('fields.financeSource.label')"
									  v-model="editableItemSource"
									  :rules="validation.source"
									  :items="filteredFinanceSources"
									  :loading="isDictionariesLoading"
									  item-text="name"
									  item-value="id"
									  color="blue"
									  required
									  :placeholder="$t('fields.financeSource.placeholder')">
					</frp-autocomplete>
					
					<frp-autocomplete :label="$t('fields.quarter.label')"
									  v-model="editableItemQuarter"
									  :rules="validation.quarter"
									  :items="dynamicQuarters"
									  :loading="isDictionariesLoading"
									  item-text="title"
									  item-value="id"
									  color="blue"
									  required
									  auto
									  :placeholder="$t('fields.quarter.label')">
					</frp-autocomplete>
					
					<frp-date-field :label="$t('fields.date.label')"
									v-model="editableItemPaymentDate"
									:rules="validation.paymentDate"
									required
									:placeholder="$t('fields.date.placeholder')">
					</frp-date-field>
					
					<frp-text-field :label="$t('fields.amount.labelRUB')"
									v-model.number="editableItemAmount"
									:rules="validation.amount"
									type="number"
									required
									:placeholder="$t('fields.amount.placeholder')">
					</frp-text-field>
					
					<frp-checkbox v-model="editableItemIsForced"
								  :true-value="true"
								  :false-value="false"
								  class="mb-4 mt-0"
								  color="blue"
								  :text="$t('buttons.isForcedPayment')">
					</frp-checkbox>
					
					<v-row>
						<v-col>
							<frp-checkbox v-model="editableItemIsCession"
										  :true-value="true"
										  :false-value="false"
										  class="mb-4 mt-0"
										  color="blue"
										  :text="$t('buttons.isCessionPayment')">
							</frp-checkbox>
						</v-col>
						<v-col>
							<frp-checkbox v-model="editableItemIsReserve"
										  :true-value="true"
										  :false-value="false"
										  class="mb-4 mt-0"
										  color="blue"
										  :text="$t('buttons.isReservePayment')">
							</frp-checkbox>
						</v-col>
					</v-row>
					
					<frp-textarea :label="$t('fields.paymentDescription.label')"
								  autocomplete="off"
								  :placeholder="$t('fields.paymentDescription.placeholder')"
								  outlined
								  v-model="editableItemDescription">
					</frp-textarea>
				</v-form>
			</template>

			<template #footer>
				<frp-btn outlined
						 @click="handleCancelCreateItem"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 color="blue"
						 dark
						 :loading="isFormSaving"
						 :disabled="!stateContainsUnsavedChanges || !isFormValid"
						 @click="handleCreateItem">
					{{ $t("buttons.addPayment") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</loan-content-layout>
	<loan-cash-flow-loader v-else></loan-cash-flow-loader>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpDialog from "@/components/dialogs/FrpDialog";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete";
import FrpDateField from "@/components/fields/FrpDateField";
import FrpRadioGroup from "@/components/fields/FrpRadioGroup";
import FrpRangeDateField from "@/components/fields/FrpRangeDateField";
import FrpTextField from "@/components/fields/FrpTextField";
import FrpIcon from "@/components/icon/FrpIcon";
import LoanContentLayout from "@/components/layouts/LoanContentLayout";
import FrpCheckbox from "@/components/fields/FrpCheckbox";
import FrpTextarea from "@/components/fields/FrpTextarea";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/loan/routes";
import { CashFlowPaymentExpenseCategoryTypeEnum } from "@/store/loan/modules/cashFlow/types/CashFlowPaymentExpenseCategoryTypeEnum";
import { TreasuryStatementOperationType } from "@/store/loan/modules/treasuryStatement/types/treasuryStatementOperationType";
import storeManager from "@/store/manager";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import { assign, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import formMixin from "Mixins/formMixin";
import { listMixin } from "Mixins/listMixin";
import { i18n } from "Plugins/index";
import { MIN_BORROWERS_QUERY_LENGTH } from "Store/loan/modules/cashFlow/constants";
import CashFlowFilter from "Store/loan/modules/cashFlow/types/cashFlowFilter";
import { CashFlowPaymentType } from "@/store/loan/modules/cashFlow/types/cashFlowPaymentType";
import LoanCashFlowLoader from "Views/loan/cashFlow/LoanCashFlowLoader";
import { namespace, getterTypes, mutationTypes, actionTypes } from "Store/loan/modules/cashFlow/types";
import { getterTypes as loanUserGetterTypes } from "Store/loan/modules/user/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

const loanUserHelpers = createNamespacedHelpers(storeManager.loan.loanUser.namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.cashFlow")
	},
	mixins: [listMixin, colorsMixin, formMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			dateFormat,
			formatDate,
			formatCurrency,
			CashFlowPaymentType,
			CashFlowPaymentExpenseCategoryTypeEnum,
			MIN_BORROWERS_QUERY_LENGTH,
			isUpdateItemDialogOpened: false,
			isDeleteItemDialogOpened: false,
			isNewItemDialogOpened: false,
			isNewItemFormValid: false,
			isUpdateItemFormValid: false,
			idItemToDelete: null,
			isDataValid: false,
			internalFilterValues: {
				startPaymentDate: "",
				endPaymentDate: "",
				quarterCodeIds: [],
				paymentExpenseIds: [],
				paymentType: ""
			}
		};
	},
	computed: {
		...loanUserHelpers.mapGetters({
		    isLoanUserBorrower: loanUserGetterTypes.isLoanUserBorrower
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			isDictionariesLoading: state => state.isDictionariesLoading,
			isFullMode: state => state.isFullMode,
			reservedTotalAmount: state => state.reservedTotalAmount,
			editableItem: state => state.editableItem,
			quarters: state => state.quarters,
			financeSources: state => state.financeSources,
			dynamicQuarters: state => state.dynamicQuarters,
			dynamicFinanceSources: state => state.dynamicFinanceSources,
			allFinanceSources: state => state.allFinanceSources,
			paymentExpenses: state => state.paymentExpenses,
			isItemDeleting: state => state.isItemDeleting
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			paymentTypes: getterTypes.paymentTypes,
			borrowerRolePaymentTypes: getterTypes.borrowerRolePaymentTypes,
			borrowers: getterTypes.borrowers
		}),
		headers() {
			const items = [
				{
					text: this.$t("tables.numberPaymentOrder"),
					value: "numberPaymentOrder",
					class: "text-caption",
					width: "2%",
					sortable: false
				},
				{
					text: this.$t("tables.plannedPaymentDate"),
					value: "paymentDate",
					class: "text-caption",
					width: "5%",
					sortable: false
				},
				{
					text: this.$t("tables.amountRUB"),
					value: "amount",
					class: "text-caption",
					width: "5%",
					sortable: false
				},
				{
					text: this.$t("tables.paymentExpense"),
					value: "paymentExpense",
					class: "text-caption",
					width: "7%",
					sortable: false
				},
				{
					text: "",
					value: "action1",
					class: "text-caption",
					width: "1%",
					sortable: false
				},
				{
					text: "",
					value: "action2",
					class: "text-caption",
					width: "1%",
					sortable: false
				}
			];

			if(this.isFullMode) {
				items.splice(1, 0,
					{
						text: this.$t("tables.quarter"),
						value: "quarter",
						class: "text-caption",
						width: "5%",
						sortable: false
					},
					{
						text: this.$t("tables.financeSource"),
						value: "source",
						class: "text-caption",
						width: "6%",
						sortable: false
					});
				items.splice(6, 0,
					{
						text: this.$t("tables.isForcedPayment"),
						value: "isForced",
						class: "text-caption",
						width: "8%",
						sortable: false
					},
					{
						text: this.$t("tables.paymentDescription"),
						value: "description",
						class: "text-caption",
						width: "11%",
						sortable: false
					}
				);
			}

			return items;
		},
		filteredPaymentExpenses() {
			const filterPaymentType = this.internalFilterValues.paymentType;
			const isAllIncomeOrAllPaymentsType = filterPaymentType === CashFlowPaymentType.ALL_INCOME || filterPaymentType === CashFlowPaymentType.ALL_PAYMENTS;
			
			if(isAllIncomeOrAllPaymentsType)
				return this.paymentExpenses.filter(x => !x.isLoan && x.category !== CashFlowPaymentExpenseCategoryTypeEnum.CESSIONS);

			if(!filterPaymentType)
				return this.paymentExpenses;

			return this.paymentExpenses.filter(x => {
				if(filterPaymentType === CashFlowPaymentType.LOAN)
					return x.isLoan;
				if(filterPaymentType === CashFlowPaymentType.MAIN_DEBT)
					return x.isMainDebt;
				if(filterPaymentType === CashFlowPaymentType.PERCENT)
					return x.isPercent;
				if(filterPaymentType === CashFlowPaymentType.PENALTY)
					return x.isPenalty;
				if(filterPaymentType === CashFlowPaymentType.OVERDUE)
					return x.isOver;
				if(filterPaymentType === CashFlowPaymentType.CESSION)
					return x.category === CashFlowPaymentExpenseCategoryTypeEnum.CESSIONS;
			});
		},
		loanIssuancePaymentExpenseId() {
			return this.paymentExpenses.find(x => x.category === CashFlowPaymentExpenseCategoryTypeEnum.LOAN_ISSUANCE)?.id || null;
		},
		filteredFinanceSources() {
			//Если выбранная статье ДДС "Выдача займа" у нас отображаются все субсидии, иначе, только по проекту
			if (this.editableItem.paymentExpenseId === this.loanIssuancePaymentExpenseId) {
				return this.allFinanceSources;
			} else {
				return this.dynamicFinanceSources;
			}
		},
		sum() {
			return formatCurrency(this.getSum(this.formattedItems));
		},
		filterPaymentDatesRange: {
			get() {
				return [this.internalFilterValues.startPaymentDate, this.internalFilterValues.endPaymentDate];
			},
			set([startDate, endDate]) {
				this.internalFilterValues.startPaymentDate = startDate;
				this.internalFilterValues.endPaymentDate = endDate;
			}
		},
		editableItemNumberPaymentOrder: {
			get() {
				return this.editableItem.numberPaymentOrder;
			},
			set(value) {
				this.setEditableItemNumberPaymentOrder(value);
			}
		},
		editableItemQuarter: {
			get() {
				return this.editableItem.quarterId;
			},
			set(value) {
				this.setEditableItemQuarter(value);
			}
		},
		editableItemProjectId: {
			get() {
				return this.editableItem.projectId;
			},
			set(value) {
				this.setEditableItemProjectId(value);
			}
		},
		editableItemSource: {
			get() {
				return this.editableItem.financingSourceId;
			},
			set(value) {
				this.setEditableItemSource(value);
			}
		},
		editableItemPaymentDate: {
			get() {
				return this.editableItem.paymentDate;
			},
			set(value) {
				this.setEditableItemPaymentDate(value);
			}
		},
		editableItemAmount: {
			get() {
				return this.editableItem.amount;
			},
			set(value) {
				this.setEditableItemAmount(value);
			}
		},
		editableItemPaymentExpense: {
			get() {
				return this.editableItem.paymentExpenseId;
			},
			set(value) {
				this.setEditableItemPaymentExpense(value);
			}
		},
		editableItemIsForced: {
			get() {
				return this.editableItem.isForced;
			},
			set(value) {
				this.setEditableItemIsForced(value);
			}
		},
		editableItemIsCession: {
			get () {
				return this.editableItem.isCession;
			},
			set(value) {
				this.setEditableItemIsCession(value);
			}
		},
		editableItemIsReserve: {
			get() {
				return this.editableItem.isReserve;
			},
			set(value) {
				this.setEditableItemIsReserve(value);
			}
		},
		editableItemDescription: {
			get() {
				return this.editableItem.description;
			},
			set(value) {
				this.setEditableItemDescription(value);
			}
		},
		editableItemActiveDate: {
			get() {
				return this.editableItem.activeDate;
			},
			set(value) {
				this.setEditableItemActiveDate(value);
			}
		},
		isActiveDateVisible() {
			const item = this.formattedItems.find(x => x.id === this.editableItem.id);
			if(item && item.paymentExpenseId && this.editableItem.paymentExpenseId)
				return this.paymentExpenses.find(x => item.paymentExpenseId === x.id).isOver &&
					!this.paymentExpenses.find(x => this.editableItem.paymentExpenseId === x.id).isOver;
		},
		filter() {
			return {
				startPaymentDate: this.internalFilterValues.startPaymentDate,
				endPaymentDate: this.internalFilterValues.endPaymentDate,
				quarterCodeIds: this.internalFilterValues.quarterCodeIds,
				paymentExpenseIds: this.internalFilterValues.paymentExpenseIds,
				paymentType: this.internalFilterValues.paymentType
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new CashFlowFilter(), this.filterValues);
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			createItem: actionTypes.createItem,
			updateItems: actionTypes.updateItem,
			updateItem: actionTypes.updateItem,
			deleteItem: actionTypes.deleteItem,
			updateDynamicDictionaries: actionTypes.updateDynamicDictionaries
		}),
		...mapMutations({
			resetEditableItem: mutationTypes.RESET_EDITABLE_ITEM,
			setEditableItem: mutationTypes.SET_EDITABLE_ITEM,
			setEditableItemNumberPaymentOrder: mutationTypes.SET_EDITABLE_ITEM_NUMBER_PAYMENT_ORDER,
			setEditableItemPaymentDate: mutationTypes.SET_EDITABLE_ITEM_PAYMENT_DATE,
			setEditableItemQuarter: mutationTypes.SET_EDITABLE_ITEM_QUARTER,
			setEditableItemSource: mutationTypes.SET_EDITABLE_ITEM_SOURCE,
			setEditableItemPaymentExpense: mutationTypes.SET_EDITABLE_ITEM_PAYMENT_EXPENSE,
			setEditableItemAmount: mutationTypes.SET_EDITABLE_ITEM_AMOUNT,
			setEditableItemDescription: mutationTypes.SET_EDITABLE_ITEM_DESCRIPTION,
			setEditableItemIsForced: mutationTypes.SET_EDITABLE_ITEM_IS_FORCED,
			setEditableItemIsCession: mutationTypes.SET_EDITABLE_ITEM_IS_CESSION,
			setEditableItemIsReserve: mutationTypes.SET_EDITABLE_ITEM_IS_RESERVE,
			setEditableItemActiveDate: mutationTypes.SET_EDITABLE_ITEM_ACTIVE_DATE,
			setEditableItemProjectId: mutationTypes.SET_EDITABLE_ITEM_PROJECT_ID,
			resetFilter: mutationTypes.RESET_FILTER,
			setFilterStartPaymentDate: mutationTypes.SET_FILTER_START_PAYMENT_DATE,
			setFilterEndPaymentDate: mutationTypes.SET_FILTER_END_PAYMENT_DATE,
			setFilterQuarterCodeIds: mutationTypes.SET_FILTER_QUARTER_CODE_IDS,
			setFilterPaymentExpenseIds: mutationTypes.SET_FILTER_PAYMENT_EXPENSE_IDS,
			setFilterPaymentType: mutationTypes.SET_FILTER_PAYMENT_TYPE
		}),
		getSum(items) {
			return items.map(x => x.amount).reduce((x, sum) => sum += +x, 0);
		},
		handleOpenUpdateItemDialog(item) {
			this.isUpdateItemDialogOpened = true;
			this.setEditableItem(item);
			this.updateDynamicDictionaries(item.projectId);
		},
		async handleOpenNewItemDialog() {
			this.isNewItemDialogOpened = true;
			await this.$nextTick(() => {
				this.$refs[this.refs.form].resetValidation();
			});
			this.setEditableItemProjectId(+this.$route.params.projectId);
		},
		async handleUpdateItem() {
			await this.updateItem();
			this.resetEditableItem();
			this.isUpdateItemDialogOpened = false;
		},
		handleCancelUpdateItem() {
			this.isUpdateItemDialogOpened = false;
			this.resetEditableItem();
		},
		handleCancelCreateItem() {
			this.isNewItemDialogOpened = false;
			this.resetEditableItem();
		},
		handleCancelItemDeleting() {
			this.isDeleteItemDialogOpened = false;
			this.idItemToDelete = null;
		},
		handleOpenDeleteItemDialog(item) {
			this.isDeleteItemDialogOpened = true;
			this.idItemToDelete = item;
		},
		async handleDeleteItem() {
			await this.deleteItem(this.idItemToDelete);
			this.idItemToDelete = null;
			this.isDeleteItemDialogOpened = false;
		},
		async handleCreateItem() {
			await this.createItem();
			this.$refs[this.refs.form].resetValidation();
			this.isNewItemDialogOpened = false;
		},
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterStartPaymentDate(this.internalFilterValues.startPaymentDate);
			this.setFilterEndPaymentDate(this.internalFilterValues.endPaymentDate);
			this.setFilterQuarterCodeIds(this.internalFilterValues.quarterCodeIds);
			this.setFilterPaymentExpenseIds(this.internalFilterValues.paymentExpenseIds || []);
			this.setFilterPaymentType(this.internalFilterValues.paymentType);
		}
	},
	async created() {
		await this.initializeStore();
	},
	watch: {
		"internalFilterValues.paymentType"() {
			this.internalFilterValues.paymentExpenseIds =
				(this.internalFilterValues.paymentExpenseIds || []).filter(x => this.filteredPaymentExpenses.some(y => y.id === x));
		}
	},
	components: {
		LoanContentLayout,
		FrpRadioGroup,
		FrpBtn,
		FrpIcon,
		FrpAutocomplete,
		FrpRangeDateField,
		FrpDateField,
		FrpTextField,
		FrpDialog,
		LoanCashFlowLoader,
		FrpCheckbox,
		FrpTextarea
	}
};
</script>
