import { mutationTypes, actionTypes, getterTypes, namespace } from "@/store/loan/modules/cashFlow/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree, Store, MutationPayload } from "vuex";
import AbortService from "@/services/abortService";
import FormMixinBuilder from "@/store/shared/form";
import ListingMixinBuilder from "@/store/shared/listing";
import PagingMixinBuilder from "@/store/shared/paging";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import { plainToInstance } from "class-transformer";
import CashFlowState from "@/store/loan/modules/cashFlow/types/cashFlowState";
import CashFlowItem from "@/store/loan/modules/cashFlow/types/cashFlowItem";
import router from "@/router/loan";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/loan/dictionaries";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import mapper from "@/store/loan/modules/cashFlow/mapper";
import { LoanPaymentsProcessingController } from "@/api/loan/paymentsProcessing";
import ApiCashFlowItem from "@/api/loan/types/paymentsProcessing/apiCashFlowItem";
import { cloneDeep } from "lodash";
import { RouteNames } from "@/router/loan/routes";
import ApiGetCashFlowItemsParameters from "@/api/loan/types/paymentsProcessing/apiGetCashFlowItemsParameters";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import CashFlowRouteQuery from "@/store/loan/modules/cashFlow/types/cashFlowRouteQuery";
import CashFlowRouteQueryService from "@/store/loan/modules/cashFlow/services/cashFlowRouteQueryService";
import CashFlowFilter from "@/store/loan/modules/cashFlow/types/cashFlowFilter";
import { CashFlowPaymentType } from "@/store/loan/modules/cashFlow/types/cashFlowPaymentType";
import ApiBorrower from "@/api/loan/types/dictionaries/apiBorrower";
import { CashFlowUserTypeEnum } from "@/store/loan/modules/cashFlow/types/CashFlowUserTypeEnum";
import storeManager from "@/store/manager";
import userTypes from "@/store/loan/modules/user/types";

const abortService = new AbortService();

const loanPaymentsProcessingController = new LoanPaymentsProcessingController(abortService);
const dictionariesController = new DictionariesController(abortService);

const defaultRouteQuery = new CashFlowRouteQuery(1, "", "", "", [], []);
const routeQueryService = new CashFlowRouteQueryService(defaultRouteQuery);
const updateListingBatchService = new BatchService(({ interval: 100 }));
const updateDictionariesBatchService = new BatchService(({ interval: 100 }));
const routeMixin = (new RouteMixinBuilder<CashFlowState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["newItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new CashFlowState(
			new ListingModel<CashFlowItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			formMixin.state(),
			snapshotMixin.state(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<CashFlowState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<CashFlowState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<CashFlowState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_PAYMENT_TYPE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_START_PAYMENT_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_END_PAYMENT_DATE):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_QUARTER_CODE_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_PAYMENT_EXPENSE_IDS):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
		case resolveMutation(namespace, mutationTypes.SET_EDITABLE_ITEM_PROJECT_ID):
		{
			updateDictionariesBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateDynamicDictionaries), mutation.payload);
			});

			break;
		}
	}
};

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<CashFlowState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			return plainToInstance(CashFlowItem, {
				...x
			});
		});
	},
	[getterTypes.paymentTypes]: state => {
		const filteredPaymentTypes = state.paymentExpenseTypes.filter(x => x.key !== CashFlowPaymentType.ALL_PAYMENTS);
		return filteredPaymentTypes.map(x => {
			return {
				label: x.value,
				value: x.key
			};
		});
	},
	[getterTypes.borrowerRolePaymentTypes]: state => {
		const filteredPaymentTypes = state.paymentExpenseTypes.filter(x => x.key === CashFlowPaymentType.LOAN || x.key === CashFlowPaymentType.ALL_PAYMENTS);
		return filteredPaymentTypes.map(x => {
			return {
				label: x.value,
				value: x.key
			};
		})
	},
	[getterTypes.borrowers]: state => {
		return state.borrowers.map(x => plainToInstance(ApiBorrower, {
			...x,
			name: `${x.shortName} №${x.applicationNumber}`
		}));
	}
};

const actions = <ActionTree<CashFlowState, any>>{
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, rootGetters, state }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		
		// Если у пользователя роль - заемщик, то дефолтный фильтр - CashFlowPaymentType.ALL_PAYMENTS, иначе CashFlowPaymentType.ALL_INCOME
		const isLoanUserBorrower = rootGetters[resolveGetter(storeManager.loan.loanUser.namespace, userTypes.getterTypes.isLoanUserBorrower)];
		
		if (!state.filter.paymentType)
			commit(mutationTypes.SET_FILTER_PAYMENT_TYPE, isLoanUserBorrower ? CashFlowPaymentType.ALL_PAYMENTS : CashFlowPaymentType.ALL_INCOME);
		
		await Promise.all([
			dispatch(actionTypes.updateListingItems),
			dispatch(actionTypes.fetchDictionaries)
		]);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			const { items, type, reservedTotalAmount } = await loanPaymentsProcessingController.getCashFlowItems(router.currentRoute.params.projectId,
				mapper.map(state, CashFlowState, ApiGetCashFlowItemsParameters));

			commit(mutationTypes.SET_LISTING_ITEMS, items.map(x => mapper.map(x, ApiCashFlowItem, CashFlowItem)));

			commit(mutationTypes.SET_IS_FULL_MODE, type === CashFlowUserTypeEnum.FULL);
			commit(mutationTypes.SET_RESERVED_TOTAL_AMOUNT, reservedTotalAmount);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchDictionaries]({ commit }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);

		try {
			const [
				quarters, 
				financeSources, 
				paymentExpenses, 
				borrowers, 
				paymentExpenseTypes,
				allFinanceSources
			] = await Promise.all([
				dictionariesController.getProjectQuarters(router.currentRoute.params.projectId),
				dictionariesController.getProjectFinanceSources(router.currentRoute.params.projectId),
				dictionariesController.getPaymentExpenses(),
				dictionariesController.getBorrowers(),
				dictionariesController.getPaymentExpenseTypes(),
				dictionariesController.getAllFinanceSources()
			]);

			commit(mutationTypes.SET_QUARTERS, quarters);
			commit(mutationTypes.SET_FINANCE_SOURCES, financeSources);
			commit(mutationTypes.SET_PAYMENT_EXPANSES, paymentExpenses);
			commit(mutationTypes.SET_BORROWERS, borrowers);
			commit(mutationTypes.SET_PAYMENT_EXPENSE_TYPES, paymentExpenseTypes);
			commit(mutationTypes.SET_ALL_FINANCE_SOURCES, allFinanceSources);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.updateDynamicDictionaries]({ commit }, projectId) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);

		if(!projectId) {
			commit(mutationTypes.SET_DYNAMIC_QUARTERS, []);
			commit(mutationTypes.SET_DYNAMIC_FINANCE_SOURCES, []);
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
			return;
		}

		try {
			const [quarters, financeSources] = await Promise.all([
				dictionariesController.getProjectQuarters(projectId),
				dictionariesController.getProjectFinanceSources(projectId)
			]);

			commit(mutationTypes.SET_DYNAMIC_QUARTERS, quarters);
			commit(mutationTypes.SET_DYNAMIC_FINANCE_SOURCES, financeSources);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.createItem]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		try {
			await loanPaymentsProcessingController.createCashFlowItem(mapper.map({
				...state.editableItem,
				projectId: +router.currentRoute.params.projectId
			}, CashFlowItem, ApiCashFlowItem));

			alertService.addInfo(AlertKeys.CASH_FLOW_ITEM_SUCCESS_CREATED);

			await dispatch(actionTypes.updateListingItems);

			commit(mutationTypes.RESET_EDITABLE_ITEM);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateItem]({ dispatch, commit, rootState, state, getters }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await loanPaymentsProcessingController.updateCashFlowItem(state.editableItem.id,
				mapper.map(state.editableItem, CashFlowItem, ApiCashFlowItem));

			await dispatch(actionTypes.updateListingItems);

			alertService.addInfo(AlertKeys.CASH_FLOW_ITEM_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.deleteItem]({ commit, state }, itemId) {
		commit(mutationTypes.SET_IS_ITEM_DELETING, true);

		try {
			await loanPaymentsProcessingController.deleteCashFlowItem(itemId);

			alertService.addInfo(AlertKeys.CASH_FLOW_ITEM_SUCCESS_DELETED);
			commit(mutationTypes.SET_LISTING_ITEMS, state.listing.items.filter(x => x.id !== itemId));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ITEM_DELETING, false);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);

		commit(mutationTypes.SET_PAGING_PAGE, routeQuery.page);
		commit(mutationTypes.SET_FILTER_PAYMENT_TYPE, routeQuery.filterPaymentType);
		commit(mutationTypes.SET_FILTER_START_PAYMENT_DATE, routeQuery.filterStartPaymentDate);
		commit(mutationTypes.SET_FILTER_END_PAYMENT_DATE, routeQuery.filterEndPaymentDate);
		commit(mutationTypes.SET_FILTER_PAYMENT_EXPENSE_IDS, routeQuery.filterPaymentExpenseIds);
		commit(mutationTypes.SET_FILTER_QUARTER_CODE_IDS, routeQuery.filterQuarterCodeIds);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.CASH_FLOW,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.CASH_FLOW,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<CashFlowState>>{
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM](state) {
		state.editableItem = new CashFlowItem();
	},
	[mutationTypes.SET_EDITABLE_ITEM](state, value) {
		state.editableItem = cloneDeep(value);
	},
	[mutationTypes.SET_IS_ITEM_DELETING](state, value) {
		state.isItemDeleting = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_NUMBER_PAYMENT_ORDER](state, value) {
		state.editableItem.numberPaymentOrder = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_PAYMENT_DATE](state, value) {
		state.editableItem.paymentDate = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_QUARTER](state, value) {
		state.editableItem.quarterId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_SOURCE](state, value) {
		state.editableItem.financingSourceId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_PROJECT_ID](state, value) {
		state.editableItem.projectId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_PAYMENT_EXPENSE](state, value) {
		state.editableItem.paymentExpenseId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_AMOUNT](state, value) {
		state.editableItem.amount = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_DESCRIPTION](state, value) {
		state.editableItem.description = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_IS_FORCED](state, value) {
		state.editableItem.isForced = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_IS_CESSION](state, value) {
		state.editableItem.isCession = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_IS_RESERVE](state, value) {
		state.editableItem.isReserve = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACTIVE_DATE](state, value) {
		state.editableItem.activeDate = value;
	},
	[mutationTypes.SET_QUARTERS](state, value) {
		state.quarters = value;
	},
	[mutationTypes.SET_FINANCE_SOURCES](state, value) {
		state.financeSources = value;
	},
	[mutationTypes.SET_PAYMENT_EXPANSES](state, value) {
		state.paymentExpenses = value;
	},
	[mutationTypes.SET_BORROWERS](state, value) {
		state.borrowers = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new CashFlowFilter();
	},
	[mutationTypes.SET_FILTER_START_PAYMENT_DATE](state, value) {
		state.filter.startPaymentDate = value;
	},
	[mutationTypes.SET_FILTER_END_PAYMENT_DATE](state, value) {
		state.filter.endPaymentDate = value;
	},
	[mutationTypes.SET_FILTER_QUARTER_CODE_IDS](state, value) {
		state.filter.quarterCodeIds = value;
	},
	[mutationTypes.SET_FILTER_PAYMENT_EXPENSE_IDS](state, value) {
		state.filter.paymentExpenseIds = value;
	},
	[mutationTypes.SET_FILTER_PAYMENT_TYPE](state, value) {
		state.filter.paymentType = value;
	},
	[mutationTypes.SET_DYNAMIC_FINANCE_SOURCES](state, value) {
		state.dynamicFinanceSources = value;
	},
	[mutationTypes.SET_DYNAMIC_QUARTERS](state, value) {
		state.dynamicQuarters = value;
	},
	[mutationTypes.SET_PAYMENT_EXPENSE_TYPES](state, value) {
		state.paymentExpenseTypes = value;
	},
	[mutationTypes.SET_ALL_FINANCE_SOURCES](state, value) {
		state.allFinanceSources = value;
	},
	[mutationTypes.SET_IS_FULL_MODE](state, value) {
		state.isFullMode = value;
	},
	[mutationTypes.SET_RESERVED_TOTAL_AMOUNT](state, value) {
		state.reservedTotalAmount = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const cashFlowModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default cashFlowModule;
