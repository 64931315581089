import { createMap, createMapper, MappingProfile, forMember, mapFrom, addProfile } from "@automapper/core";
import { classes } from "@automapper/classes";
import { convertIsoToNumber, convertToZonedIso, convertToTimestamp } from "@/utils/dates";
import ApiPayment from "@/api/loan/types/loanSchedule/apiPayment";
import Payment from "@/store/loan/modules/loanSchedule/modules/payments/types/payment";

const mapper = createMapper({
	strategyInitializer: classes()
});

const paymentsProfile: MappingProfile = (mapper) => {
	createMap(mapper, ApiPayment, Payment,
		forMember(d => d.date, mapFrom(x => convertToTimestamp(x.date))),
		forMember(d => d.planMainDebt, mapFrom(x => x.planMainDebt)),
		forMember(d => d.factLoan, mapFrom(x => x.factLoan)),
		forMember(d => d.factMainDebt, mapFrom(x => x.factMainDebt)),
		forMember(d => d.overdue, mapFrom(x => x.overdue)),
		forMember(d => d.factOverdue, mapFrom(x => x.factOverdue)),
		forMember(d => d.balanceMainDebt, mapFrom(x => x.balanceMainDebt)),
		forMember(d => d.hasCessions, mapFrom(x => x.hasCessions)),
		forMember(d => d.assignedLoan, mapFrom(x => x.assignedLoan)),
		forMember(d => d.assignedMainDebt, mapFrom(x => x.assignedMainDebt)),
		forMember(d => d.balanceAssign, mapFrom(x => x.balanceAssign)),
	);

	createMap(mapper, Payment, ApiPayment,
		forMember(d => d.date, mapFrom(x => convertToZonedIso(x.date))),
		forMember(d => d.planMainDebt, mapFrom(x => x.planMainDebt)),
		forMember(d => d.factLoan, mapFrom(x => x.factLoan)),
		forMember(d => d.factMainDebt, mapFrom(x => x.factMainDebt)),
		forMember(d => d.overdue, mapFrom(x => x.overdue)),
		forMember(d => d.factOverdue, mapFrom(x => x.factOverdue)),
		forMember(d => d.balanceMainDebt, mapFrom(x => x.balanceMainDebt)),
		forMember(d => d.hasCessions, mapFrom(x => x.hasCessions)),
		forMember(d => d.assignedLoan, mapFrom(x => x.assignedLoan)),
		forMember(d => d.assignedMainDebt, mapFrom(x => x.assignedMainDebt)),
		forMember(d => d.balanceAssign, mapFrom(x => x.balanceAssign)),
	);
};

addProfile(mapper, paymentsProfile);

export default mapper;
