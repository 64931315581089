export default class Payment {
	date: number;
	planMainDebt: number;
	factLoan: number;
	factMainDebt: number;
	overdue: number;
	factOverdue: number;
	balanceMainDebt: number;
	hasCessions: boolean;
	assignedLoan: number;
	assignedMainDebt: number;
	balanceAssign: number;

	constructor(
		date: number = 0,
		planMainDebt: number = 0,
		factLoan: number = 0,
		factMainDebt: number = 0,
		overdue: number = 0,
		factOverdue: number = 0,
		balanceMainDebt: number = 0,
		hasCessions: boolean = false,
		assignedLoan: number = 0,
		assignedMainDebt: number = 0,
		balanceAssign: number = 0
	)
	{
		this.date = date;
		this.planMainDebt = planMainDebt;
		this.factLoan = factLoan;
		this.factMainDebt = factMainDebt;
		this.overdue = overdue;
		this.factOverdue = factOverdue;
		this.balanceMainDebt = balanceMainDebt;
		this.hasCessions = hasCessions;
		this.assignedLoan = assignedLoan;
		this.assignedMainDebt = assignedMainDebt;
		this.balanceAssign = balanceAssign;
	}
}
