<template>
	<div class="d-flex flex-column" style="gap: 8px">
		<div v-for="group in groups" :key="group.type">
			<loan-nested-card>
				<span class="text-body-1 font-weight-bold mx-4 mb-2">{{ group.typeName }}</span>
				<v-data-table :headers="headers"
							  :loading-text="$t('tables.loading')"
							  hide-default-footer
							  item-key="sourceId"
							  :items="group.items"
							  :loading="isItemsLoading"
							  :items-per-page="-1"
							  :mobile-breakpoint="mobileBreakpoint"
							  :options="options"
							  :item-class="() => 'text-body-2'"
							  class="d-flex flex-column table-headers-top black--text upcoming-payments-table">
					<template #item.amount="{ item }">
						<span>{{ formatNullableTableValue(formatCurrency(item.amount)) }}</span>
					</template>
					
					<template #item.amount="{ item }">
						<span>{{ formatNullableTableValue(formatCurrency(item.amount)) }}</span>
					</template>
					
					<template #item.financingSourceId="{ item }">
						<span>{{ financeSources.find(x => x.id === item.sourceId).name }}</span>
					</template>
					
					<template #item.action="{ item: { type, sourceId } }">
						<frp-btn elevation="0"
								 dark
								 :loading="loadingFiles.some(x => x.type === group.type && x.sourceId === sourceId)"
								 color="blue"
								 @click="downloadPaymentOrder({ sourceId, type: group.type })">
							{{ $t("buttons.generatePaymentOrder") }}
						</frp-btn>
					</template>
				</v-data-table>
			</loan-nested-card>
		</div>
	</div>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import { PaymentOrderType } from "Store/loan/modules/upcomingPayments/types/paymentOrderType";
import { formatCurrency, formatNullableTableValue } from "Utils/formatting";
import { createNamespacedHelpers } from "vuex";
import { actionTypes, namespace } from "Store/loan/modules/upcomingPayments/types";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	data() {
		return {
			mobileBreakpoint: 880,
			options: {},
			formatCurrency,
			formatNullableTableValue,
			headers: [
				{
					text: this.$t("tables.paymentSum"),
					value: "amount",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "40%",
					sortable: false
				},
				{
					text: this.$t("tables.financeSource"),
					value: "financingSourceId",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "30%",
					sortable: false
				},
				{
					text: "",
					value: "action",
					width: "1%",
					sortable: false
				}
			]
		};
	},
	computed: {
		...mapState({
			loadingFiles: state => state.loadingFiles,
			isItemsLoading: state => state.isItemsLoading,
			financeSources: state => state.financeSources,
			payments: state => state.payments
		}),
		groups() {
			const orderTypes = {
				[PaymentOrderType.OVERDUE_MAIN_DEBT_PENALTY_REPAYMENT]: "paymentPenaltyMainDebt",
				[PaymentOrderType.OVERDUE_MAIN_DEBT_INTEREST_PENALTY_REPAYMENT]: "paymentPenaltyPercent",
				[PaymentOrderType.LEGAL_PENALTY_REPAYMENT]: "paymentPenaltyOther"
			};
			
			return Object.entries(orderTypes).map(([key, value]) => {
				const items = [];
				
				this.financeSources.forEach(source => items.push({
					amount: this.payments.find(x => x.financingSourceId === source.id)[value],
					sourceId: source.id
				}));
				
				return {
					type: key,
					typeName: this.$t(`aliases.upcomingPaymentsPaymentOrderType.${key}`),
					items
				};
			});
		}
	},
	methods: {
		...mapActions({
			downloadPaymentOrder: actionTypes.downloadPaymentOrder
		})
	},
	components: { FrpBtn, LoanNestedCard }
};
</script>
