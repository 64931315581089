<template>
	<loan-nested-card>
		<v-data-table :headers="headers"
					  :loading-text="$t('tables.loading')"
					  hide-default-footer
					  item-key="type"
					  :items="items"
					  :loading="isItemsLoading"
					  :items-per-page="-1"
					  :mobile-breakpoint="mobileBreakpoint"
					  :options="options"
					  :item-class="() => 'text-body-2 '"
					  class="d-flex flex-column table-headers-top black--text upcoming-payments-table">
			<template #item.amount="{ item }">
				<span>{{ formatNullableTableValue(formatCurrency(item.amount)) }}</span>
			</template>

			<template #item.action="{ item: { type, sourceId } }">
				<frp-btn elevation="0"
						 dark
						 :loading="loadingFiles.some(x => x.type === type && x.sourceId === sourceId)"
						 color="blue"
						 @click="downloadPaymentOrder({ sourceId, type })">
					{{ $t("buttons.generatePaymentOrder") }}
				</frp-btn>
			</template>
		</v-data-table>
	</loan-nested-card>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import LoanNestedCard from "Components/layouts/LoanNestedCard";
import { PaymentOrderType } from "Store/loan/modules/upcomingPayments/types/paymentOrderType";
import { formatCurrency, formatNullableTableValue } from "Utils/formatting";
import { createNamespacedHelpers } from "vuex";
import { actionTypes, namespace } from "Store/loan/modules/upcomingPayments/types";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	data() {
		return {
			mobileBreakpoint: 880,
			options: {},
			formatCurrency,
			formatNullableTableValue,
			headers: [
				{
					text: this.$t("tables.category"),
					value: "category",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "60%",
					sortable: false
				},
				{
					text: this.$t("tables.paymentSum"),
					value: "amount",
					class: "text-body-2 font-weight-bold black--text py-2",
					width: "40%",
					sortable: false
				},
				{
					text: "",
					value: "action",
					width: "1%",
					sortable: false
				}
			]
		};
	},
	computed: {
		...mapState({
			loadingFiles: state => state.loadingFiles,
			isItemsLoading: state => state.isItemsLoading,
			payments: state => state.payments
		}),
		items() {
			return [
				{
					category: this.$t("titles.mainDebt"),
					amount: this.payments[0]?.paymentPenaltyMainDebt,
					sourceId: this.payments[0]?.financingSourceId,
					type: PaymentOrderType.OVERDUE_MAIN_DEBT_PENALTY_REPAYMENT
				},
				{
					category: this.$t("titles.percents"),
					amount: this.payments[0]?.paymentPenaltyPercent,
					sourceId: this.payments[0]?.financingSourceId,
					type: PaymentOrderType.OVERDUE_MAIN_DEBT_INTEREST_PENALTY_REPAYMENT
				},
				{
					category: this.$t("titles.otherPenalty"),
					amount: this.payments[0]?.paymentPenaltyOther,
					sourceId: this.payments[0]?.financingSourceId,
					type: PaymentOrderType.LEGAL_PENALTY_REPAYMENT
				}
			];
		}
	},
	methods: {
		...mapActions({
			downloadPaymentOrder: actionTypes.downloadPaymentOrder
		})
	},
	components: { FrpBtn, LoanNestedCard }
};
</script>
